<template>
  <div class="recurring-order-actions">
    <button v-if="eId !== 'create'" class="delete" @click="doDelete">
      Delete
    </button>
    <button class="cancel" @click="cancel">Cancel</button>
    <button class="save" @click="save">Save</button>
  </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
import { db } from "../../main";

export default {
  name: "RecurringOrderActions",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      eId: (state) => state.recurringOrders.eId,
      eName: (state) => state.recurringOrders.eName,
      eNote: (state) => state.recurringOrders.eNote,
      eActive: (state) => state.recurringOrders.eActive,
      eOrderLines: (state) => state.recurringOrders.eOrderLines,
      eCompany: (state) => state.recurringOrders.eCompany,
      eDays: (state) => state.recurringOrders.eDays,
      recurringOrders: (state) => state.recurringOrders.recurringOrders,
      appSettings: (state) => state.settings.appSettings,
      uid: (state) => state.settings.user.uid,
    }),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("companies", ["getCompanyByVismaId"]),
  },
  mounted() {},
  async created() {},
  methods: {
    doDelete: function () {
      const verify = confirm(
        "Are you sure you want to delete this subscription, this cant be undone?"
      );

      if (verify) {
        this.$store.dispatch("recurringOrders/deleteOrder");
      }
    },
    cancel: function () {
      this.$store.dispatch("recurringOrders/clearEditing");
    },
    save: async function () {
      if (this.eName.trim() === "") {
        alert("Name is required");
        return;
      }

      if (this.eDays.length === 0) {
        alert("At least one day is required");
        return;
      }

      if (this.eOrderLines.length === 0) {
        alert("At least one product is required");
        return;
      }

      // Go through orderlines and make sure count is a number
      const orderLines = this.eOrderLines.map((line) => {
        return {
          ...line,
          count: parseFloat(line.count),
        };
      });

      const data = {
        id: this.eId,
        name: this.eName,
        days: this.eDays,
        orderLines: orderLines,
        active: this.eActive,
        orderNote: this.eNote,
        companyVismaId: parseInt(this.eCompany),
        companyFirebaseId: this.getCompanyByVismaId(parseInt(this.eCompany)).id,
        uid: this.uid,
      };

      let recurringOrderId = this.eId;
      if (this.eId === "create") {
        const docRef = await db.collection("recurringOrders").add(data);
        recurringOrderId = docRef.id;
      } else {
        await db
          .collection("recurringOrders")
          .doc(recurringOrderId)
          .update(data);
      }

      this.$toast.center("Successfully saved subscription");
      this.$store.dispatch("recurringOrders/clearEditing");
    },
  },
  beforeDestroy() {},
};
</script>
