<template>
  <div class="shortlist-edit-footer">
    <div
      v-if="
        editingType === 'home' &&
        (appBrand === 'kvalitetsfisk' || appBrand === 'fsg')
      "
    >
      <div class="upsell-public-radio" ref="listPublic">
        <input type="radio" id="private" :value="false" v-model="listPublic" />
        <label for="private">Private</label>

        <input type="radio" id="public" :value="true" v-model="listPublic" />
        <label for="public">Public</label>
      </div>
    </div>

    <button @click="exitList" class="leave-button">Exit without saving</button>
    <button @click="saveList">Save</button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "UpsellEditFooter",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading: (state) => state.cartUpsell.loading,
      editingType: (state) => state.cartUpsell.editingType,
      appBrand: (state) => state.settings.appSettings.app,
    }),
    listPublic: {
      get() {
        return this.$store.state.cartUpsell.listPublic;
      },
      set(value) {
        this.$store.commit("cartUpsell/setListPublic", value);
      },
    },
  },
  components: {},
  methods: {
    exitList: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.dispatch("cartUpsell/exitEditing");
    },
    saveList: function () {
      if (this.loading === true) {
        return;
      }
      this.$store.commit("cartUpsell/setLoading", true);

      this.$store.dispatch("cartUpsell/saveList").then(() => {
        this.$store.commit("cartUpsell/setLoading", false);
      });
    },
  },
};
</script>
